.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #FFFFFF;
  height: 100dvh;
  width: 100vw;
}

a {
  display: block;
  color: #F15A22;
}

.Nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc( 100% - 40px );
  height: 74px;
  font-family: "JetBrains Mono", monospace;
  color: #F15A22;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  gap: 10px;
  padding: 0px 24px;
}

.Logo {
  height: 28px;
  margin-right: 16px;
}

.NavButtons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.Git {
  height: 32px;
}

.SettingsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border: 2px solid #CCCCCC;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.SettingsContainer:hover {
  background-color: #F1F1F1;
}

.Settings {
  height: 16px;
  width: 16px;
}

.SettingsDropdown {
  position: absolute;
  top: 40px;
  right: -50%;
  background-color: white;
  border-radius: 24px;
  z-index: 100;
  width: 225px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  /* opacity: 0; 
  transform: translateY(-10px);
  transition: opacity 0.2*/
}

.SettingsMenuItem {
  display: flex;
  flex-direction: row;
  margin: 8px;
  padding: 16px;
  font-family: "JetBrains Mono", monospace;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  border-radius: 16px;
  text-align: left;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.SettingsMenuItem:hover {
  background-color: #F1F1F1;
}

input[type="radio"] {
  position: relative;
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  transition: border-color 0.3s ease;
}

input[type="radio"]:checked {
  border-color: #F15A22;
}

input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #F15A22;
}

.Footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 48px;
  font-family: "JetBrains Mono", monospace;
  color: #F15A22;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #F1F1F1;
  border-radius: 24px;
  overflow: scroll;
}

.calculator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin: 40px;
}

input[type="text"] {
  caret-color: #F15A22;
  display: flex;
  height: auto;
  width: auto;
  max-width: 100%;
  padding: 24px 0px;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 10px;
  border-radius: 24px;
  background: #FFF;
  color: #000000;
  font-family: Helvetica;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  border: 0px;
}

input::placeholder {
  color: #959595; 
}

.operation, .operationMenuButton {
  cursor: pointer;
  outline: 0px solid #F15A22;
  transition: outline 0.05s;
  border-radius: 100%;
}

.operation:hover, .operationMenuButton:hover {
  outline: 2px solid #F15A22;
}

.solution {
  display: flex;
  height: auto;
  width: auto;
  max-width: 100%;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 10px;
  border-radius: 24px;
  background: #FFF;
  font-family: Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 0px;
}

.SettingsMenuItem label {
  cursor: pointer;
}